.header-contacts {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;

    &-item {
        display: inline-flex;
        align-items: center;
        margin-left: 25px;

        &__icon {
            fill: $color-general;
            margin-right: 6px;
        }

        &__text {
            font-size: 14px;
            line-height: 25px;
            color: $color-text;

            &_email {
                text-decoration: underline;
            }

            &_phone {
                font-weight: 600;
            }
        }
    }
}

.btn-primary:hover {
    background-color: #fff !important;
    border-color: #72BF44 !important;
    color: #72BF44 !important;
}